import { config } from "@my/config/src/tamagui.config"
import { ComponentProps, useCallback, useMemo } from "react"
import Select, { StylesConfig } from "react-select"
import { ColorTokens, GetThemeValueForKey, getTokens, Text, useTheme, Variable } from "tamagui"

export type Option = {
  label: string | number | boolean
  value: string | number | boolean | null | undefined
}
export type Options = Option[]
type ReactSelectProps = ComponentProps<typeof Select>
type ReactSelectOnChange = ReactSelectProps["onChange"]
export interface CustomSelectProps extends Omit<ReactSelectProps, "onChange"> {
  color?: ColorTokens
  editable: boolean
  fontSize?: string | number
  onChange: (value: any) => void
  options: Options
  textAlign?: "right" | "left" | "center"
  value: string | null
  paddingLeft?: number | GetThemeValueForKey<"paddingLeft"> | undefined
}

/**
 * Select component the web app. Utilizes the react-select library.
 *
 * @see https://react-select.com/
 *
 * @returns {JSX.Element} The rendered Select component
 */
export const SelectInput: React.FC<CustomSelectProps> = ({
  color,
  editable,
  fontSize = "16px",
  onChange,
  options,
  placeholder,
  textAlign = "right",
  value,
  paddingLeft,
  ...props
}): JSX.Element => {
  const theme = useTheme()
  const tokens = getTokens()

  const colorKey = color?.replace("$", "") ?? "color"
  const fontSettings = {
    fontFamily: config.fonts.body.family,
    fontSize,
  }
  const colourStyles: StylesConfig = {
    container: (styles) => ({
      ...styles,
      cursor: "pointer",
      width: "100%",
      textAlign,
      minHeight: 40,
      ...fontSettings,
    }),
    control: (styles) => ({
      ...styles,
      alignContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      textAlign,
      width: "100%",
    }),
    indicatorsContainer: (styles, { isDisabled }) => ({
      ...styles,
      opacity: isDisabled ? 0 : 1,
      transform: isDisabled ? "scaleX(0)" : "scaleX(1)",
      transformOrigin: "center right",
      transition: "opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease, width 0.3s ease",
      visibility: isDisabled ? "hidden" : "visible",

      /**
       * These custom widths allow for the Select to be right aligned with the other input elements.
       *
       * Disabled: Input value to be right aligned with the other input text.
       * Active: Caret to be right aligned with the other input text.
       */
      width: isDisabled ? (tokens.space["$sm"] as Variable).variable : "39px",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: (tokens.space["$xs"] as Variable).variable,
      marginRight: (tokens.space["$sm"] as Variable).variable,
      color: theme.icon?.get(),
      ":hover": {
        color: theme.icon?.get(),
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.background?.get(),
      color: theme[colorKey]?.get(),
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor:
        isSelected ? theme.backgroundSelected?.get()
        : isFocused ? theme.backgroundHover?.get()
        : theme.background?.get(),
      color: theme.text?.get(),
    }),
    placeholder: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? theme.textSecondary?.get() : theme.textLink?.get(),
      textAlign,
      ...fontSettings,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: editable ? theme.textLink?.get() : theme.textSecondary?.get(),
      textAlign,
      ...fontSettings,
    }),
    valueContainer: (styles) => ({
      ...styles,
      alignContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: textAlign === "right" ? "row-reverse" : "row",
      flexGrow: 2,
      padding: 0,
      textAlign,
    }),
  }

  const handleChange: ReactSelectOnChange = useCallback(
    (newValue: unknown) => {
      const selectedOption = newValue as Option
      onChange(selectedOption.value === notReallyNullValue ? null : selectedOption.value)
    },
    [onChange],
  )

  const placeholderString = placeholder as string
  const placeholderNode = useMemo(
    () => (
      <Text color={color}>
        {placeholderString || options.find((option) => !option.value)?.label}
      </Text>
    ),
    [placeholder, options, color],
  )

  const nonNullOptions = useMemo(
    () => options.map((option) => ({ ...option, value: option.value ?? notReallyNullValue })),
    [options],
  )
  const nonNullValue = value ?? notReallyNullValue

  return (
    <Select
      isDisabled={!editable}
      options={nonNullOptions}
      placeholder={placeholderNode}
      styles={colourStyles}
      {...props}
      onChange={handleChange}
      value={nonNullOptions.find((option) => option.value === nonNullValue)}
    />
  )
}

// This is honestly a bit ridiculous. This select component does not support null values.
const notReallyNullValue = "native-select-not-really-null-value"
