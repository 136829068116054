import { ChangeEvent } from "react"
import { NativeSyntheticEvent, TextInputChangeEventData } from "react-native"

/**
 * Type guard function to check if e is a ChangeEvent<HTMLInputElement>
 * @param e - The event object to check
 */
export function isChangeEvent(e: unknown): e is ChangeEvent<HTMLInputElement> {
  return (e as ChangeEvent<HTMLInputElement>)?.target !== undefined
}

export function isSyntheticEvent(e: unknown): e is NativeSyntheticEvent<TextInputChangeEventData> {
  return (e as NativeSyntheticEvent<TextInputChangeEventData>)?.nativeEvent !== undefined
}
