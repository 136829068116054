import { generateShadow } from "@my/utils/src/styleUtils/shadow"
import { format, isValid, isSameDay } from "date-fns"
import { FC, useCallback, useEffect, useRef, useState, memo, useMemo } from "react"
import { GestureResponderEvent, Modal, Platform, Pressable } from "react-native"
import RNDateTimePicker from "react-native-ui-datepicker"
import {
  Adapt,
  FontSizeTokens,
  GetThemeValueForKey,
  Popover,
  SizableText,
  TextProps,
  useMedia,
  useTheme,
  View,
  XStack,
  YStack,
} from "tamagui"
import { Icon, IconSize } from "../icon/Icon"
import { RowColorType } from "../inputs/StyledInputs"

export type DateTimePickerProps = {
  color?: RowColorType
  editable?: boolean
  placeholder?: string
  value?: Date | string | null
  minDate?: Date
  maxDate?: Date
  includeTime?: boolean
  dateFormat?: string
  paddingRight?: number | GetThemeValueForKey<"space">
  onChange: (date: Date | null) => void
  onPress?: (e: GestureResponderEvent) => void
  fontSize?: number | FontSizeTokens
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  color,
  dateFormat,
  editable = true,
  fontSize = "$5",
  includeTime,
  maxDate,
  minDate,
  onChange,
  onPress,
  placeholder,
  value,
  paddingRight = 0,
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [initialView, setInitialView] = useState<"day" | "month" | "year" | "time">("day")
  const { gtSm } = useMedia()

  const dateFormats = {
    date: "MMM d, yyyy",
    time: "h:mm a",
  }

  const parsedDate =
    typeof value === "string" ? new Date(value)
    : value instanceof Date ? value
    : new Date()

  const dateValue = isValid(parsedDate) ? parsedDate : new Date()
  const [editDate, setEditDate] = useState<Date>(dateValue)

  useEffect(() => {
    /*
     * Make sure as we get a new value, we update editDate. Technically everything works without this
     * except for one edge case. If endDate is changed to prior to startDate, DataPointView will update
     * startDate to the same value as endDate. If you open the date picker for startDate, it will still
     * show the previous value and not the updated one that was corrected to avoid a startDate after an
     * endDate.
     */
    setEditDate(dateValue)
  }, [value])

  const handleOnDone = useCallback(() => {
    onChange(editDate)
    setOpen(false)
  }, [onChange, editDate])

  const hasDateChanged = useCallback((newDate: Date) => !isSameDay(editDate, newDate), [editDate])

  // Ref to the day field
  const dayRef = useRef<React.ElementRef<typeof SizableText>>(null)
  // Ref to the time field
  const timeRef = useRef<React.ElementRef<typeof SizableText>>(null)

  const handleDatePickerPress = (
    e: GestureResponderEvent,
    onPress?: (e: GestureResponderEvent) => void,
  ) => {
    if (e.target === timeRef.current) {
      setInitialView("time")
    } else {
      setInitialView("day")
    }
    if (onPress) {
      onPress(e)
    }
  }

  /**
   * A standard onPress callback function.
   *
   * @callback onPress
   * @param {GestureResponderEvent} e
   */

  /**
   * FormattedDate component
   *
   * This component is responsible for rendering the formatted date text
   * within the DateTimePicker. It handles the display of the date and time,
   * as well as any custom formatting specified by the user.
   *
   * @component
   * @param {onPress} onPress - Callback function to be called when the date is pressed
   * @param {TextProps} props - Additional props to be passed to the underlying Text component
   * @returns {JSX.Element} An element that renders the formatted date
   */
  const FormattedDate: FC<TextProps & { onPress?: (e: GestureResponderEvent) => void }> = ({
    onPress,
    ...props
  }) => {
    /**
     * TouchWrapper component
     *
     * This component wraps its children with a touchable area, providing a consistent
     * touch interaction across different platforms (iOS, Android, and web).
     * It handles the logic for setting the initial view of the date picker and
     * triggers the onPress callback.
     *
     * @component
     * @param {Object} props - The component props
     * @param {React.ReactNode} props.children - The child elements to be wrapped
     * @param {onPress} [props.onPress] - Callback function to be called when the wrapper is pressed
     * @returns {JSX.Element} A rendered element that wraps its children with touch functionality
     */
    const TouchWrapper = ({
      children,
      onPress,
    }: {
      children: React.ReactNode
      onPress?: (e: GestureResponderEvent) => void
    }) => {
      return (
        <Pressable
          onPress={(e) => {
            handleDatePickerPress(e, onPress)
          }}
          android_ripple={{
            color: theme.backgroundFocus?.val,
            borderless: false,
          }}
          style={({ pressed }) => [
            {
              opacity: pressed ? 0.7 : 1,
            },
          ]}
        >
          <XStack
            paddingVertical="$1"
            alignItems="center"
            justifyContent="flex-end"
            backgroundColor="transparent"
            hoverStyle={{ backgroundColor: "$backgroundHover" }}
            borderRadius="$2"
            paddingRight={paddingRight}
          >
            {children}
          </XStack>
        </Pressable>
      )
    }

    // If a default value is not provided, show the placeholder
    if (!value) {
      return (
        <TouchWrapper onPress={onPress} {...props}>
          <SizableText ref={dayRef} {...props} fontSize={fontSize}>
            {placeholder}
          </SizableText>
        </TouchWrapper>
      )
    }

    const formattedDateValues = {
      date: format(editDate, dateFormats.date),
      time: format(editDate, dateFormats.time),
      override: dateFormat ? format(editDate, dateFormat) : null,
    }

    // If an override value is provided, show it
    if (formattedDateValues.override) {
      return (
        <TouchWrapper onPress={onPress} {...props}>
          <XStack>
            <SizableText ref={dayRef} {...props}>
              {formattedDateValues.override}
            </SizableText>
          </XStack>
        </TouchWrapper>
      )
    }

    // Otherwise, show the date and time
    return (
      <TouchWrapper onPress={onPress} {...props}>
        <SizableText ref={dayRef} color={color} fontSize={fontSize} fontWeight="400">
          {formattedDateValues.date}
        </SizableText>

        {includeTime && (
          <SizableText ref={timeRef} color={color} fontSize={fontSize} fontWeight="400">
            &nbsp;at&nbsp;{formattedDateValues.time}
          </SizableText>
        )}
      </TouchWrapper>
    )
  }

  const DatePickerContent = memo(
    ({
      editDate,
      minDate,
      maxDate,
      includeTime,
      initialView,
      handleOnChange,
      handleOnDone,
      hasDateChanged,
      theme,
    }: {
      editDate: Date
      minDate?: Date
      maxDate?: Date
      includeTime?: boolean
      initialView: "day" | "month" | "year" | "time"
      handleOnChange: (date: Date) => void
      handleOnDone: () => void
      hasDateChanged: (newDate: Date) => boolean
      theme: ReturnType<typeof useTheme>
    }) => {
      const commonStyles = {
        borderWidth: 1,
        borderColor: theme.borderColor?.val,
        borderRadius: 10,
        ...generateShadow(),
      }

      return (
        <YStack>
          <XStack justifyContent="flex-end" paddingBottom="$1">
            <Pressable
              style={({ pressed }) => [
                {
                  backgroundColor: pressed ? theme.backgroundPress?.val : theme.background?.val,
                },
              ]}
              onPress={handleOnDone}
            >
              <SizableText color="$textLink" fontWeight="600" fontSize="$5" paddingHorizontal="$2">
                Done
              </SizableText>
            </Pressable>
          </XStack>
          <RNDateTimePicker
            date={editDate || new Date()}
            maxDate={maxDate}
            minDate={minDate}
            mode="single"
            timePicker={includeTime}
            initialView={initialView}
            onChange={(params) => {
              let newDate: Date

              if (!params.date) {
                newDate = new Date()
              } else if (typeof params.date === "string" || typeof params.date === "number") {
                newDate = new Date(params.date)
                newDate = isValid(newDate) ? newDate : new Date()
              } else if (params.date instanceof Date) {
                newDate = isValid(params.date) ? params.date : new Date()
              } else if (
                typeof params.date === "object" &&
                typeof params.date.toDate === "function"
              ) {
                newDate = params.date.toDate()
                newDate = isValid(newDate) ? newDate : new Date()
              } else {
                console.error("Unknown date type", params.date)
                newDate = new Date()
              }

              // Check if the year/month/day has changed
              const hasChanged = hasDateChanged(newDate)
              /*
               * If the user changes the day, then taps on time picker without closing the date picker,
               * and makes any change to hours or minutes, a re-render occurs naturally but we end up showing
               * the "day" view since that is what we initially had rendered with in this scenario. This makes
               * sure as the user is interacting with date or time, we update the initial view so after
               * a re-render we'll be showing the correct view.
               */
              const newInitialView = hasChanged ? "day" : "time"
              if (newInitialView !== initialView) {
                setInitialView(newInitialView)
              }
              handleOnChange(newDate)
            }}
            calendarTextStyle={{ color: theme.color?.val }}
            headerTextStyle={{ color: theme.color?.val }}
            todayTextStyle={{ color: theme.color?.val, fontWeight: "bold" }}
            weekDaysTextStyle={{ color: theme.color?.val }}
            selectedItemColor={theme.backgroundSelected?.val}
            selectedTextStyle={{ color: theme.color?.val, fontWeight: "bold" }}
            monthContainerStyle={{ backgroundColor: theme.background?.val, ...commonStyles }}
            yearContainerStyle={{ backgroundColor: theme.background?.val, ...commonStyles }}
            timePickerTextStyle={{ color: theme.color?.val }}
            timePickerIndicatorStyle={{ backgroundColor: theme.backgroundSecondary?.val }}
            buttonPrevIcon={<Icon icon="chevron-left" size={IconSize.Small} />}
            buttonNextIcon={<Icon icon="chevron-right" size={IconSize.Small} />}
          />
        </YStack>
      )
    },
  )

  // Handles presses for web & native
  const handleFormattedDatePress = (e: GestureResponderEvent) => {
    if (editable) {
      setOpen(!open)
    }
    // Make sure to call the onPress prop if it is provided
    onPress?.(e)
  }

  const trigger = useMemo(
    () => (
      <Pressable>
        <FormattedDate
          color={color}
          cursor={editable ? "pointer" : "default"}
          fontSize={fontSize}
          textAlign="right"
          onPress={handleFormattedDatePress}
        />
      </Pressable>
    ),
    [color, editable, fontSize, handleFormattedDatePress],
  )

  const popoverContent = useMemo(
    () => (
      <Popover.Content
        borderColor="$borderColor"
        borderWidth="$0.25"
        height={400}
        marginRight="$xs"
        width={350}
        {...generateShadow()}
        animation={[
          "100ms",
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
        opacity={1}
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        scale={1}
        y={0}
      >
        <Popover.Arrow borderWidth={1} borderColor="$borderColor" />
        <DatePickerContent
          editDate={editDate}
          minDate={minDate}
          maxDate={maxDate}
          includeTime={includeTime}
          initialView={initialView}
          handleOnChange={setEditDate}
          handleOnDone={handleOnDone}
          hasDateChanged={hasDateChanged}
          theme={theme}
        />
      </Popover.Content>
    ),
    [editDate, minDate, maxDate, includeTime, initialView, handleOnDone, hasDateChanged, theme],
  )

  const modalContent = useMemo(
    () => (
      <View
        borderWidth="$0.5"
        borderColor="$borderColor"
        borderTopLeftRadius="$5"
        borderTopRightRadius="$5"
        padding="$2"
        backgroundColor="$background"
        {...generateShadow()}
      >
        <DatePickerContent
          editDate={editDate}
          minDate={minDate}
          maxDate={maxDate}
          includeTime={includeTime}
          initialView={initialView}
          handleOnChange={setEditDate}
          handleOnDone={handleOnDone}
          hasDateChanged={hasDateChanged}
          theme={theme}
        />
      </View>
    ),
    [editDate, minDate, maxDate, includeTime, initialView, handleOnDone, hasDateChanged, theme],
  )

  const formattedDateTrigger = useMemo(
    () => (
      <View>
        <FormattedDate
          color={color}
          fontSize={fontSize}
          textAlign="right"
          onPress={handleFormattedDatePress}
        />
      </View>
    ),
    [color, fontSize, handleFormattedDatePress],
  )

  // Show floating date picker on web & ipad
  return Platform.OS === "web" || gtSm ?
      <WebDatePicker open={open} setOpen={setOpen} trigger={trigger} content={popoverContent} />
    : <>
        {formattedDateTrigger}
        <NativeDatePicker open={open} setOpen={setOpen} content={modalContent} />
      </>
}

const WebDatePicker = memo(
  ({
    open,
    setOpen,
    trigger,
    content,
  }: {
    open: boolean
    setOpen: (open: boolean) => void
    trigger: React.ReactNode
    content: React.ReactNode
  }) => {
    return (
      <Popover size="$3" allowFlip={true} open={open} onOpenChange={setOpen} placement="bottom-end">
        <Popover.Trigger asChild>{trigger}</Popover.Trigger>

        <Adapt when="sm" platform="touch">
          <Popover.Sheet modal disableDrag snapPointsMode="fit">
            <Popover.Sheet.Frame>
              <Adapt.Contents />
            </Popover.Sheet.Frame>
            <Popover.Sheet.Overlay backgroundColor="transparent" />
          </Popover.Sheet>
        </Adapt>

        {content}
      </Popover>
    )
  },
)

const NativeDatePicker = memo(
  ({
    open,
    setOpen,
    content,
  }: {
    open: boolean
    setOpen: (open: boolean) => void
    content: React.ReactNode
  }) => {
    return (
      <Modal
        transparent={true}
        visible={open}
        onRequestClose={() => setOpen(false)}
        animationType="slide"
      >
        <View flex={1} justifyContent="flex-end">
          {/* Backdrop Pressable */}
          <Pressable
            style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
            onPress={() => setOpen(false)}
          />

          {/* Date Picker Container */}
          {content}
        </View>
      </Modal>
    )
  },
)
