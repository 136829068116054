import { ComponentProps, forwardRef, useRef, useImperativeHandle } from "react"
import { Input, styled } from "tamagui"

/**
 * Props for the RowInput component.
 * Extends ComponentProps<typeof Input> and adds an optional editable prop.
 */
export type RowInputProps = ComponentProps<typeof Input> & {
  /** Determines if the input is editable. Defaults to false. */
  editable?: boolean
}

/**
 * Type for the "color" prop used in RowInput.
 * This is derived from the "color" prop of RowInputProps.
 */
export type RowColorType = RowInputProps["color"]

/**
 * A styled input component designed for use in rows.
 * It extends the base Input component from Tamagui with custom styling and behavior.
 */
export const RowInput = styled(
  // Use forwardRef to allow the component to be given a ref
  forwardRef<any, RowInputProps>((props, ref) => {
    // Destructure props, setting default value for editable
    const { editable = false, style, ...rest } = props
    // Create an internal ref to hold the Input component instance
    const internalRef = useRef<Input | null>(null)

    // Use useImperativeHandle to customize the exposed ref methods
    useImperativeHandle(ref, () => ({
      // Expose a focus method that calls focus on the internal Input
      focus: () => {
        internalRef.current?.focus()
      },
      // Expose a blur method that calls blur on the internal Input
      blur: () => {
        internalRef.current?.blur()
      },
      // Spread the rest of the internal Input's properties
      ...internalRef.current,
    }))

    // Render the Input component with the processed props
    return (
      <Input
        testID="row-input"
        ref={internalRef}
        editable={editable}
        style={style}
        {...rest} // Apply the remaining props
      />
    )
  }),
  // Define custom styling for the RowInput
  {
    backgroundColor: "transparent",
    borderColor: "transparent",
    borderWidth: 0,
    color: "$color", // Use the theme color
    fontFamily: "$body", // Use the body font family from the theme
    fontSize: "$5", // Use font size 5 from the theme
    fontWeight: "$5", // Use font weight 5 from the theme
    letterSpacing: "$5", // Use letter spacing 5 from the theme
    textAlign: "right",
  },
)
